<template>
  <el-container class="app-container">
    <el-header>{{ $t('title.BasicInformation') }}</el-header>
    <el-main>
      <el-row class="row-center">
        <div class="add">
          <el-col :span="12"><div class="grid-content" />供应商编码:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.vendorCode }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />供应商名称:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.vendorName }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />供应商类型:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.vendorType ==1 ? '一级供应商':'二级供应商' }}</el-col>
        </div>
      </el-row>
      <el-row class="row-center">
        <div class="add">
          <el-col :span="12"><div class="grid-content" />纳税人识别号:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.trNumber }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />成立时间:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.trCreateTime }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />{{ $t('page.Linkman') }}:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.linkman }}</el-col>
        </div>
      </el-row>
      <el-row class="row-center">
        <div class="add">
          <el-col :span="12"><div class="grid-content" />{{ $t('page.contactNumber') }}:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.mobile }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />手机号 :</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.telphone }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />邮箱地址:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.email }}</el-col>
        </div>
      </el-row>
      <el-row class="row-center">
        <div class="add">
          <el-col :span="12"><div class="grid-content" />QQ/微信:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.qqWechat }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />供应商注册信息 :</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.address }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />退换货地址:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.salesReturnAddr }}</el-col>
        </div>
      </el-row>
      <el-row class="row-center">
        <div class="add">
          <el-col :span="12"><div class="grid-content" />最小采购数:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.qqWechat }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />站点渠道 :</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.siteSellChannel }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />支付方式:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.payType }}</el-col>
        </div>
      </el-row>
      <el-row class="row-center">
        <div class="add">
          <el-col :span="12"><div class="grid-content" />传  真:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.fax }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />境内货源地 :</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.cargoSrc }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />开票税率(%):</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.taxRates }}</el-col>
        </div>
      </el-row>
      <el-row class="row-center">
        <div class="add">
          <el-col :span="12"><div class="grid-content" />开户银行:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.bankAddr }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" /> 开户名称:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.cardManageName }}</el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" />银行账号:</el-col>
          <el-col :span="12"><div class="grid-content" />{{ DetailsDatas.cardNo }}</el-col>
        </div>
      </el-row>
      <el-row class="row-center">
        <div class="add">
          <el-col :span="12"><div class="grid-content" />三证合一或商业登记证:</el-col>
          <el-col :span="12"><div class="grid-content" /><img :src="DetailsDatas.brc" alt="暂无图片" style="width: 70px;height: 70px"></el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" /> 采购协议:</el-col>
          <el-col :span="12"><div class="grid-content" /><img :src="DetailsDatas.lfoa" alt="暂无图片" style="width: 70px;height: 70px"></el-col>
        </div>
        <div class="add">
          <el-col :span="12"><div class="grid-content" /> 开户许可证:</el-col>
          <el-col :span="12"><div class="grid-content" /><img :src="DetailsDatas.pa" alt="暂无图片" style="width: 70px;height: 70px"></el-col>
        </div>
      </el-row>

    </el-main>
  </el-container>
</template>
<script>
import { queryVendorId } from '@/api/blurbInformation.js'

export default {
  data() {
    return {
      DetailsDatas: {}
    }
  },
  mounted() {
    this._queryVendorId()
  },
  methods: {
    // 获取公司信息数据
    async _queryVendorId(query) {
      const { id } = this.$route.query
      const { datas } = await queryVendorId(id)
      this.DetailsDatas = datas
    }
  }
}
</script>
<style scope>
.add{
  width: 33.3%;
}
  .el-main  .el-row >  .add > .el-col:nth-of-type(odd){
    color: #333;
    margin: 20px 0;
    line-height: 20px;
    text-align: right;
  }
  .el-main .el-row > .add > .el-col:nth-of-type(even){
    color: #555;
    margin: 20px 0;
    line-height: 20px;
    text-align: left;
    padding: 0 20px;

  }
  .app-container{
    padding: 0;
  }
  .el-main .add .el-col >  i{
    color: red;
  }
</style>
